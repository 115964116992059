import React from 'react';

function FoodOrdersList({ customers, onCustomerAction }) {
  return (
    <div className="food-orders-list">
      <h2>Food Orders</h2>
      {customers.map(customer => (
        <div key={customer.id} className="food-order-item">
          <span>{customer.name || `Customer ${customer.id}`}</span>
          <div>
            <button onClick={() => onCustomerAction(customer.id, 'addFoodItem')}>Add Item</button>
            <button onClick={() => onCustomerAction(customer.id, 'endOrder')}>End Order</button>
            <button onClick={() => onCustomerAction(customer.id, 'cancelOrder')}>Cancel</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FoodOrdersList;