const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchCustomers = async (storeName) => {
  const response = await fetch(`${API_BASE_URL}/rest/v1/customers?select=*&store=eq.${storeName}`, {
    headers: { 'Authorization': `Bearer ${API_KEY}` },
  });
  return response.json();
};

export const createCustomer = async (storeName, gender) => {
  const response = await fetch(`${API_BASE_URL}/customers`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ store: storeName, gender, entryTime: new Date().toISOString() }),
    headers: { 'Authorization': `Bearer ${API_KEY}` },
  });
  return response.json();
};

export const updateCustomer = async (customerId, action, data = {}) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ action, ...data }),
  });
  return response.json();
};

export const deleteCustomer = async (customerId) => {
  await fetch(`${API_BASE_URL}/customers/${customerId}`, { method: 'DELETE' });
};