import React from 'react';
import { ReactComponent as MaleIcon } from '../icons/male-icon.svg';
import { ReactComponent as FemaleIcon } from '../icons/female-icon.svg';

function NewCustomerButtons({ onNewCustomer }) {
  return (
    <div className="new-customer-buttons">
      <button onClick={() => onNewCustomer('Male')} aria-label="New Male Customer">
        <MaleIcon />
      </button>
      <button onClick={() => onNewCustomer('Female')} aria-label="New Female Customer">
        <FemaleIcon />
      </button>
    </div>
  );
}

export default NewCustomerButtons;