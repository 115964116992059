import React, { useState, useEffect } from 'react';
import CustomerList from './components/CustomerList';
import FoodOrdersList from './components/FoodOrdersList';
import NewCustomerButtons from './components/NewCustomerButtons';
import { fetchCustomers, createCustomer, updateCustomer, deleteCustomer } from './api';
import './App.css';

function App() {
  const [storeName, setStoreName] = useState('');
  const [formattedStoreName, setFormattedStoreName] = useState('');

  useEffect(() => {
    // Extract the store name from the URL path
    const path = window.location.pathname;
    const extractedStoreName = path.split('/')[1] || '';
    setStoreName(extractedStoreName);
    
    const capitalizedStoreName = extractedStoreName.charAt(0).toUpperCase() + extractedStoreName.slice(1);
    setFormattedStoreName(capitalizedStoreName ? `${capitalizedStoreName} Store` : "NO STORE");
  }, []);

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const loadCustomers = async () => {
      const data = await fetchCustomers(storeName);
      console.log(data);
      setCustomers(data);
    };
    loadCustomers();
  }, [storeName]);

  const handleNewCustomer = async (gender) => {
    const newCustomer = await createCustomer(storeName, gender);
    setCustomers([...customers, newCustomer]);
  };

  const handleCustomerAction = async (customerId, action, data = {}) => {
    const updatedCustomer = await updateCustomer(customerId, action, data);
    setCustomers(customers.map(c => c.id === customerId ? updatedCustomer : c));
  };

  const handleDeleteCustomer = async (customerId) => {
    await deleteCustomer(customerId);
    setCustomers(customers.filter(c => c.id !== customerId));
  };

  return (
    <div className="App">
      <h1>{formattedStoreName}</h1>
      <NewCustomerButtons onNewCustomer={handleNewCustomer} />
      <CustomerList 
        customers={customers.filter(c => c.entry && !c.exit)}
        onCustomerAction={handleCustomerAction}
        onDeleteCustomer={handleDeleteCustomer}
      />
      <FoodOrdersList 
        customers={customers.filter(c => c.foodOrderTime && !c.foodReceivedTime)}
        onCustomerAction={handleCustomerAction}
      />
    </div>
  );
}

export default App;