import React from 'react';

function CustomerList({ customers, onCustomerAction, onDeleteCustomer }) {
  return (
    <div className="customer-list">
      <h2>Customers in Store</h2>
      {customers.map(customer => (
        <div key={customer.id} className="customer-item">
          <span>{customer.name || `Customer ${customer.id}`} ({customer.gender})</span>
          <div>
            <button onClick={() => onCustomerAction(customer.id, 'exit')}>Exit</button>
            <button onClick={() => onCustomerAction(customer.id, 'addNote')}>Add Note</button>
            <button onClick={() => onCustomerAction(customer.id, 'orderFood')}>Order Food</button>
            <button onClick={() => onDeleteCustomer(customer.id)}>Delete</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomerList;